import { KeyValueList, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { prefFieldName } from "@aeo/core/utils";

import { KeyValueRow } from "..";
import { ExaminationCard } from ".";

export const LogisticOne = () => {
  const logistic = fields.logisticOne;
  const outsourceOrganization = fields.outsourceOrganization;

  return (
    <>
      <ExaminationCard {...logistic.has_audit_trail} type="boolean" />
      <ExaminationCard {...logistic.audit_trail} NA />

      <ExaminationCard {...logistic.accounting_systems} />

      <ExaminationCard {...logistic.can_differentiate} type="boolean-no-na" />
      <ExaminationCard {...logistic.differentiate} NA />

      <ExaminationCard {...logistic.it_activity} NA />

      <ExaminationCard {...logistic.has_it_outsourced} type="boolean" />

      <ExaminationCard
        name="outsource_organizations"
        title={
          <KeyValueList
            ItemContent={({ prefix }) => (
              <>
                <KeyValueRow
                  {...prefFieldName(outsourceOrganization.name, prefix)}
                />
                <KeyValueRow
                  {...prefFieldName(outsourceOrganization.address, prefix)}
                />
                <KeyValueRow
                  {...prefFieldName(outsourceOrganization.code_fiscal, prefix)}
                />
                <KeyValueRow
                  {...prefFieldName(outsourceOrganization.description, prefix)}
                />
              </>
            )}
            name="outsource_organizations"
            title="Companii"
            Wrapper={({ children }) => (
              <>
                <div className="divider mb-10 mt-10" />
                {children}
              </>
            )}
          />
        }
      />

      <ExaminationCard {...logistic.has_internal_control} type="boolean" />
      <ExaminationCard {...logistic.internal_control} NA />
      <ExaminationCard {...logistic.has_audit_control} type="boolean" />
      <ExaminationCard {...logistic.is_include_parcels} type="boolean" />

      <ExaminationCard {...logistic.recent_audit_report} type="attachments" />

      <div className="color-gray">{logistic.incorrect_registration.info}</div>
      <WhiteSpace v="1rem" />

      <ExaminationCard {...logistic.incorrect_registration} />
      <ExaminationCard {...logistic.incorrect_data} />
      <ExaminationCard {...logistic.incorrect_business_processes} />

      <ExaminationCard attachmentList />
    </>
  );
};
