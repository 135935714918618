import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const ComplianceLegislation = () => {
  const legislation = fields.complianceLegislation;

  return (
    <>
      <KeyValueRow
        {...legislation.has_breaches_legislation}
        type="boolean-no-na"
      />
      <KeyValueRow {...legislation.violations_description} NA />
      <KeyValueRow {...legislation.notify_violations} NA />
      <KeyValueRow {...legislation.introduced_measures} NA />
      <KeyValueRow {...legislation.register_measures} type="boolean" NA />

      <WhiteSpace v="1rem" />
      <div className="divider"></div>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...legislation.convicted_economic_crimes} type="boolean" />
      <KeyValueRow {...legislation.economic_crimes} NA />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...legislation.apply_other_customs} type="boolean" />
      <KeyValueRow {...legislation.other_customs} NA />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...legislation.any_refused_applications} type="boolean" />
      <KeyValueRow {...legislation.refused_applications} NA />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
