import { WhiteSpace } from "@aeo/core";
import fields from "@aeo/core/fields";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const OrganizationStructure = () => {
  const organizationStructure = fields.organizationStructure;

  return (
    <>
      <KeyValueRow {...organizationStructure.departament_description} />
      <KeyValueRow {...organizationStructure.employee_description} />
      <KeyValueRow {...organizationStructure.category} type="nomenclature" />
      <AttachmentsList />
      <KeyValueRow {...organizationStructure.employee_count} />
      <div>{organizationStructure.accept_information.label}</div>
      <WhiteSpace />
      <KeyValueRow
        {...organizationStructure.agreement_publication}
        type="boolean"
      />
      <KeyValueRow
        {...organizationStructure.partial_agreement_publication}
        type="boolean"
      />
      <KeyValueRow {...organizationStructure.employee_name} />
      <KeyValueRow {...organizationStructure.employee_function} />
    </>
  );
};
