import { WhiteSpace } from "@aeo/core";
import fields from "@aeo/core/fields";

import { ExaminationCard } from ".";

export const OrganizationStructure = () => {
  const organizationStructure = fields.organizationStructure;

  return (
    <>
      <ExaminationCard {...organizationStructure.departament_description} />
      <ExaminationCard {...organizationStructure.employee_description} />
      <ExaminationCard
        {...organizationStructure.category}
        type="nomenclature"
      />
      <ExaminationCard attachmentList />
      <ExaminationCard {...organizationStructure.employee_count} />
      <div>{organizationStructure.accept_information.label}</div>
      <WhiteSpace />
      <ExaminationCard
        {...organizationStructure.agreement_publication}
        type="boolean"
      />
      <ExaminationCard
        {...organizationStructure.partial_agreement_publication}
        type="boolean"
      />
      <ExaminationCard {...organizationStructure.employee_name} />
      <ExaminationCard {...organizationStructure.employee_function} />
    </>
  );
};
