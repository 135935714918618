import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";

import { KeyValueRow, KeyValueList } from "..";

import { AttachmentsList } from "..";

export interface PersonListProps {
  title?: string;
}

export const PersonList = ({ title }: PersonListProps) => {
  const person = fields.person;

  return (
    <>
      <h4>{messages.SAQTabs.directors.title}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueList
        name={[]}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
            <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
            <KeyValueRow
              {...prefFieldName(person.birthday, prefix)}
              type="date"
            />
            <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />

            <WhiteSpace v="1rem" />
            <AttachmentsList name={makeNamePath(prefix, "attachments")} />
          </>
        )}
        title={title}
      />
    </>
  );
};
