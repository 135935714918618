import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { prefFieldName } from "@aeo/core/utils";

import { AttachmentsList } from "..";
import { KeyValueList, KeyValueRow } from "..";

export const CommercialActivity = () => {
  const commercial = fields.commercialActivity;
  const subdivision = fields.commercialSubdivision;
  const person = fields.person;

  return (
    <>
      <KeyValueRow {...commercial.description} />
      <KeyValueRow {...commercial.roles} type="nomenclature-list" />
      <WhiteSpace v="2rem" />

      <p className="color-gray">{messages.titles.subdivisionsActivity}</p>

      <WhiteSpace />

      <p className="color-gray">{messages.titles.individualSubdivisions}</p>

      <KeyValueList
        noStyle
        name={commercial.subdivisions.name}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(subdivision.name, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.address, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.phone, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.email, prefix)} />
            <KeyValueRow {...prefFieldName(subdivision.activity, prefix)} />
            <KeyValueRow
              {...prefFieldName(subdivision.employee_count, prefix)}
            />
            <KeyValueRow
              {...prefFieldName(subdivision.exists_third_party, prefix)}
              type="boolean"
            />

            <KeyValueList
              title={`${messages.titles.thirdPartySubdivision} ${String(
                messages.titles.juridicPerson,
              ).toLowerCase()}`}
              ident={1}
              name={[
                ...(prefix as string[]),
                subdivision.third_party_juridic.name,
              ]}
              ItemContent={({ prefix }) => (
                <>
                  <KeyValueRow {...prefFieldName(person.name, prefix)} />
                  <KeyValueRow {...prefFieldName(person.idno, prefix)} />
                  <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                  <KeyValueRow {...prefFieldName(person.address, prefix)} />
                  <KeyValueRow
                    {...prefFieldName(subdivision.third_party, prefix)}
                  />
                  <KeyValueRow
                    {...prefFieldName(subdivision.employee_count, prefix)}
                  />
                </>
              )}
            />

            <KeyValueList
              title={`${messages.titles.thirdPartySubdivision} ${String(
                messages.titles.physicPerson,
              ).toLowerCase()}`}
              ident={1}
              name={[
                ...(prefix as string[]),
                subdivision.third_party_physic.name,
              ]}
              ItemContent={({ prefix }) => (
                <>
                  <KeyValueRow {...prefFieldName(person.first_name, prefix)} />
                  <KeyValueRow {...prefFieldName(person.last_name, prefix)} />
                  <KeyValueRow {...prefFieldName(person.birthday, prefix)} />
                  <KeyValueRow {...prefFieldName(person.idnp, prefix)} />
                  <KeyValueRow {...prefFieldName(person.phone, prefix)} />
                  <KeyValueRow {...prefFieldName(person.email, prefix)} />
                  <KeyValueRow {...prefFieldName(person.address, prefix)} />
                  <KeyValueRow
                    {...prefFieldName(subdivision.third_party, prefix)}
                  />
                </>
              )}
            />
          </>
        )}
      />

      <WhiteSpace v="2rem" />

      <KeyValueRow {...commercial.buy_from_associated} type="boolean" />
      <KeyValueList
        title={messages.titles.associatedCompany}
        name={commercial.associated_buy_companies.name}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
          </>
        )}
      />

      <WhiteSpace v="2rem" />

      <KeyValueRow {...commercial.sell_to_associated} type="boolean" />
      <KeyValueList
        title={messages.titles.associatedCompany}
        name={commercial.associated_sell_companies.name}
        ident={1}
        ItemContent={({ prefix }) => (
          <>
            <KeyValueRow {...prefFieldName(person.name, prefix)} />
            <KeyValueRow {...prefFieldName(person.idno, prefix)} />
            <KeyValueRow {...prefFieldName(person.phone, prefix)} />
            <KeyValueRow {...prefFieldName(person.email, prefix)} />
            <KeyValueRow {...prefFieldName(person.address, prefix)} />
          </>
        )}
      />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
