import fields from "@aeo/core/fields";

import { ExaminationCard } from ".";

export const FinancialSolvency = () => {
  const solvency = fields.financialSolvency;

  return (
    <>
      <ExaminationCard
        {...solvency.has_insolvency_proceedings}
        type="boolean"
      />
      <ExaminationCard {...solvency.insolvency_proceedings} NA />

      <ExaminationCard {...solvency.good_financial_situation} type="boolean" />
      <ExaminationCard {...solvency.financial_situation} />
      <ExaminationCard
        {...solvency.financial_situation_attachments}
        type="attachments"
      />

      <ExaminationCard
        {...solvency.actual_financial_reports}
        type="attachments"
        reverseNA
      />

      <ExaminationCard {...solvency.has_problems_solvency} type="boolean" />
      <ExaminationCard {...solvency.problems_solvency} NA />

      <ExaminationCard attachmentList />
    </>
  );
};
