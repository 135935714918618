import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const SecurityThree = () => {
  const securityThree = fields.securityThree;

  return (
    <>
      <ExaminationCard {...securityThree.security_goods_procedure} />
      <ExaminationCard {...securityThree.checking_security_procedure} />
      <ExaminationCard {...securityThree.employee_security_measures} />
      <ExaminationCard {...securityThree.checking_goods_seals} />

      <ExaminationCard
        {...securityThree.has_import_goods_sealed}
        type="boolean"
      />
      <ExaminationCard {...securityThree.has_types_of_goods} type="boolean" />
      <ExaminationCard {...securityThree.import_goods_sealed} NA />
      <ExaminationCard {...securityThree.has_marking_goods} type="boolean" />
      <ExaminationCard {...securityThree.marking_goods} NA />

      <ExaminationCard {...securityThree.counting_process} />
      <ExaminationCard {...securityThree.counting_process_register} />
      <ExaminationCard
        {...securityThree.has_separated_responsible}
        type="boolean"
      />

      <ExaminationCard {...securityThree.has_internal_control} type="boolean" />
      <ExaminationCard {...securityThree.internal_control} NA />

      <ExaminationCard {...securityThree.allocated_storage_subdivision} />
      <ExaminationCard {...securityThree.assigning_storage_procedure} />

      <ExaminationCard {...securityThree.has_outdoor_storage} type="boolean" />
      <ExaminationCard {...securityThree.outdoor_storage} NA />

      <ExaminationCard
        {...securityThree.has_inventorying_procedures}
        type="boolean"
      />
      <ExaminationCard {...securityThree.inventorying_procedures} NA />

      <ExaminationCard {...securityThree.has_stored_separate} type="boolean" />
      <ExaminationCard {...securityThree.criteria_separate_storage} />
      <ExaminationCard {...securityThree.register_accounting_goods} />
      <ExaminationCard {...securityThree.protected_goods_access} />
      <ExaminationCard {...securityThree.procedures_verified} />

      <ExaminationCard
        {...securityThree.has_third_party_storage}
        type="boolean"
      />
      <ExaminationCard {...securityThree.third_party_storage} NA />

      <ExaminationCard {...securityThree.production_subdivisions} />

      <ExaminationCard
        {...securityThree.has_external_production}
        type="boolean"
      />
      <ExaminationCard {...securityThree.external_production} NA />

      <ExaminationCard
        {...securityThree.has_protect_access_measures}
        type="boolean"
      />
      <ExaminationCard {...securityThree.protect_access_measures} NA />

      <ExaminationCard {...securityThree.product_packaging_procedure} />

      <ExaminationCard
        {...securityThree.has_third_party_packaging}
        type="boolean"
      />
      <ExaminationCard {...securityThree.third_party_packaging} NA />

      <ExaminationCard {...securityThree.goods_loading} />

      <ExaminationCard
        {...securityThree.has_loading_procedure}
        type="boolean"
      />

      <ExaminationCard {...securityThree.has_goods_sealed} type="boolean" />
      <ExaminationCard {...securityThree.goods_sealed} NA />

      <ExaminationCard {...securityThree.has_seals_register} type="boolean" />

      <ExaminationCard {...securityThree.seals_register} />
      <ExaminationCard {...securityThree.client_requirements} />
      <ExaminationCard {...securityThree.supervision_measures} />

      <ExaminationCard
        {...securityThree.has_checking_completion}
        type="boolean"
      />
      <ExaminationCard {...securityThree.checking_completion} NA />

      <ExaminationCard {...securityThree.checking_coming_orders} />
      <ExaminationCard {...securityThree.loading_procedure} />

      <ExaminationCard attachmentList />
    </>
  );
};
