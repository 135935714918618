import fields from "@aeo/core/fields";
import { ExaminationCard } from ".";

export const SecurityFour = () => {
  const securityFour = fields.securityFour;

  return (
    <>
      <ExaminationCard {...securityFour.verification_identity_partners} />
      <ExaminationCard {...securityFour.safety_partner_requirements} />
      <ExaminationCard {...securityFour.safety_partner_procedure} />

      <ExaminationCard {...securityFour.has_partner_breaches} type="boolean" />
      <ExaminationCard {...securityFour.partner_breaches} NA />

      <ExaminationCard {...securityFour.security_requirements} />

      <ExaminationCard
        {...securityFour.has_security_procedure}
        type="boolean"
      />
      <ExaminationCard
        {...securityFour.security_procedure_attachments}
        type="attachments"
      />
      <ExaminationCard {...securityFour.security_procedure} NA />

      <div className="color-gray">{securityFour.title_6_11_2.title}</div>
      <ExaminationCard {...securityFour.checking_new_employees} />
      <ExaminationCard {...securityFour.checking_old_employees} />
      <ExaminationCard {...securityFour.access_employees} />

      <ExaminationCard {...securityFour.has_safety_training} type="boolean" />
      <ExaminationCard {...securityFour.safety_training} NA />

      <ExaminationCard {...securityFour.has_annual_course} type="boolean" />
      <ExaminationCard
        {...securityFour.has_conducted_sessions}
        type="boolean-place"
      />
      <ExaminationCard {...securityFour.has_sessions_register} type="boolean" />

      <ExaminationCard {...securityFour.fixed_term_employee} />

      <ExaminationCard
        {...securityFour.has_security_standards}
        type="boolean"
      />
      <ExaminationCard {...securityFour.security_standards} NA />

      <ExaminationCard
        {...securityFour.has_employee_instructions}
        type="boolean"
      />

      <ExaminationCard
        {...securityFour.has_contractual_services}
        type="boolean"
      />
      <ExaminationCard {...securityFour.contractual_services} NA />

      <ExaminationCard
        {...securityFour.has_written_agreements}
        type="boolean"
      />
      <ExaminationCard {...securityFour.written_agreements} NA />

      <ExaminationCard attachmentList />
    </>
  );
};
