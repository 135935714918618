import { KeyValueList } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";

import { KeyValueRow } from "..";
import { ExaminationCard } from ".";

export const CustomsStatistic = () => {
  const statistic = fields.customsStatistic;
  const represent = fields.representStatistic;
  const client = fields.clientStatistic;

  return (
    <>
      <ExaminationCard {...statistic.carry_formalities} type="boolean" />
      <div className="color-gray">{statistic.represent_persons.label}</div>
      <ExaminationCard
        name={represent.represent_persons.name}
        title={
          <>
            <KeyValueList
              ItemContent={({ prefix }) => (
                <>
                  <KeyValueRow
                    {...represent.name}
                    name={makeNamePath(prefix, "name")}
                  />
                  <KeyValueRow
                    {...represent.idno}
                    name={makeNamePath(prefix, "idno")}
                  />
                  <KeyValueRow
                    {...represent.address}
                    name={makeNamePath(prefix, "address")}
                  />
                  <KeyValueRow
                    {...represent.direct_mode}
                    name={makeNamePath(prefix, "direct_mode")}
                    type="direct-mode"
                  />
                </>
              )}
              title={represent.represents.label}
              name={represent.represent_persons.name}
              Wrapper={({ children }) => (
                <>
                  <div className="divider mb-10 mt-10" />
                  {children}
                </>
              )}
            />
          </>
        }
      />
      <ExaminationCard
        {...represent.represent_persons_attachments}
        type="attachments"
      />

      <ExaminationCard {...statistic.has_represent} type="boolean" />
      <ExaminationCard
        name="clients"
        title={
          <>
            <KeyValueList
              ItemContent={({ prefix }) => (
                <>
                  <KeyValueRow {...prefFieldName(client.name, prefix)} />
                  <KeyValueRow
                    {...prefFieldName(client.direct_mode, prefix)}
                    type="direct-mode"
                    label="Modul"
                  />
                  <KeyValueRow {...statistic.has_represent} type="boolean" />
                </>
              )}
              title={client.clients.label}
              name="clients"
              Wrapper={({ children }) => (
                <>
                  <div className="divider mb-10 mt-10" />
                  {children}
                </>
              )}
            />
          </>
        }
      />
      <ExaminationCard {...statistic.attachments_clients} type="attachments" />
      <ExaminationCard {...statistic.who_decide_classification} />
      <ExaminationCard {...statistic.classification_measures} />
      <ExaminationCard {...statistic.register_measures} type="boolean" />
      <ExaminationCard {...statistic.monitoring_measures} type="boolean" />
      <ExaminationCard {...statistic.resource_measures} />

      <ExaminationCard {...statistic.determine_values} />
      <ExaminationCard {...statistic.correct_steps} />
      <ExaminationCard {...statistic.regularly_monitoring} type="boolean" />
      <ExaminationCard {...statistic.register_monitoring} type="boolean" />

      <ExaminationCard {...statistic.imported_overview} />
      <ExaminationCard {...statistic.internal_actions} />
      <ExaminationCard {...statistic.issuing_procedure} />
      <ExaminationCard {...statistic.has_transaction_duties} type="boolean" />
      <ExaminationCard {...statistic.transaction_duties_description} NA />

      <ExaminationCard attachmentList />
    </>
  );
};
