import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";
import { getTextWithoutLegalNumbering } from "@aeo/core/utils";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const Customer = () => {
  const person = { ...fields.person, ...fields.personCustomer };

  return (
    <>
      <h4>{getTextWithoutLegalNumbering(messages.SAQTabs.customer.title)}</h4>
      <WhiteSpace />

      <KeyValueRow {...person.first_name} />
      <KeyValueRow {...person.last_name} />
      <KeyValueRow {...person.birthday} type="date" />
      <KeyValueRow {...person.idnp} />
      <KeyValueRow {...person.phone} />
      <KeyValueRow {...person.email} />
      <KeyValueRow {...person.address} />
      <KeyValueRow {...person.attachments_persons} type="attachments" />

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
