import { capitalize } from "ebs-design";

import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import messages from "@aeo/core/messages";

import { KeyValueRow } from "..";

export const PracticalStandard = () => {
  const standard = fields.practicalStandard;

  return (
    <>
      <h4>{capitalize(messages.titles.practicalStandard)}</h4>
      <KeyValueRow {...standard.has_experience} type="boolean" />
      <KeyValueRow {...standard.experience} NA />
      <KeyValueRow {...standard.experience_attachments} type="attachments" />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...standard.has_quality_standard} type="boolean" />
      <KeyValueRow {...standard.quality_standard} NA />
      <KeyValueRow
        {...standard.quality_standard_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <h4>{messages.titles.professionalQualifications.toUpperCase()}</h4>
      <WhiteSpace v="1rem" />

      <KeyValueRow {...standard.graduated_training_course} type="boolean" />
      <KeyValueRow {...standard.training_course} NA />
      <KeyValueRow
        {...standard.training_course_attachments}
        type="attachments"
      />
    </>
  );
};
