import { BorderedCard, KeyValueList, WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";
import { makeNamePath, prefFieldName } from "@aeo/core/utils";
import { AttachmentsList } from "..";

import { KeyValueRow } from "..";

export const CustomsStatistic = () => {
  const statistic = fields.customsStatistic;
  const represent = fields.representStatistic;
  const client = fields.clientStatistic;

  return (
    <>
      <BorderedCard>
        <KeyValueRow {...statistic.carry_formalities} type="boolean" />
        <div className="color-gray">{statistic.represent_persons.label}</div>
        <KeyValueList
          title={represent.represents.label}
          name={represent.represent_persons.name}
          ItemContent={({ prefix }) => (
            <>
              <KeyValueRow
                {...represent.name}
                name={makeNamePath(prefix, "name")}
              />
              <KeyValueRow
                {...represent.idno}
                name={makeNamePath(prefix, "idno")}
              />
              <KeyValueRow
                {...represent.address}
                name={makeNamePath(prefix, "address")}
              />
              <KeyValueRow
                {...represent.direct_mode}
                name={makeNamePath(prefix, "direct_mode")}
                type="direct-mode"
              />
            </>
          )}
        />
        <KeyValueRow
          {...represent.represent_persons_attachments}
          type="attachments"
        />

        <WhiteSpace v="2rem" />
        <KeyValueRow {...statistic.has_represent} type="boolean" />
        <KeyValueList
          title={client.clients.label}
          ItemContent={({ prefix }) => (
            <>
              <KeyValueRow {...prefFieldName(client.name, prefix)} />
              <KeyValueRow
                {...prefFieldName(client.direct_mode, prefix)}
                type="direct-mode"
                label="Modul"
              />
            </>
          )}
          name="clients"
        />
        <KeyValueRow {...statistic.attachments_clients} type="attachments" />
      </BorderedCard>
      <WhiteSpace v="3rem" />
      <BorderedCard>
        <KeyValueRow {...statistic.who_decide_classification} />
        <KeyValueRow {...statistic.classification_measures} />
        <KeyValueRow {...statistic.register_measures} type="boolean" />
        <KeyValueRow {...statistic.monitoring_measures} type="boolean" />
        <KeyValueRow {...statistic.resource_measures} />
      </BorderedCard>
      <WhiteSpace v="3rem" />
      <BorderedCard>
        <KeyValueRow {...statistic.determine_values} />
        <KeyValueRow {...statistic.correct_steps} />
        <KeyValueRow {...statistic.regularly_monitoring} type="boolean" />
        <KeyValueRow {...statistic.register_monitoring} type="boolean" />
      </BorderedCard>
      <WhiteSpace v="3rem" />
      <BorderedCard>
        <KeyValueRow {...statistic.imported_overview} />
        <KeyValueRow {...statistic.internal_actions} />
        <KeyValueRow {...statistic.issuing_procedure} />
        <KeyValueRow {...statistic.has_transaction_duties} type="boolean" />
        <KeyValueRow {...statistic.transaction_duties_description} NA />
      </BorderedCard>

      <WhiteSpace v="2rem" />
      <AttachmentsList />
    </>
  );
};
