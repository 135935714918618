import { WhiteSpace } from "@aeo/core/components";
import fields from "@aeo/core/fields";

import { AttachmentsList } from "..";
import { KeyValueRow } from "..";

export const SecurityOne = () => {
  const securityOne = fields.securityOne;

  return (
    <>
      <h4>
        "Subsecţiunea 6.1. Informații generale privind siguranța și
        securitatea",
      </h4>
      <KeyValueRow {...securityOne.competent.first_name} />
      <KeyValueRow {...securityOne.competent.last_name} />
      <KeyValueRow {...securityOne.competent.function} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_assessment_risk} type="boolean" />
      <KeyValueRow {...securityOne.has_security_plan} type="boolean" />
      <KeyValueRow
        {...securityOne.security_plan_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.documents_reviewed} />
      <KeyValueRow {...securityOne.risks} />
      <KeyValueRow {...securityOne.measures} />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_subdivision_measures} type="boolean" />
      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityOne.has_instructions} type="boolean" />
      <KeyValueRow {...securityOne.staff_communication} />
      <KeyValueRow {...securityOne.instructions_documented} />
      <KeyValueRow
        {...securityOne.instructions_documented_attachments}
        type="attachments"
      />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_incidents} type="boolean" />
      <KeyValueRow {...securityOne.incidents} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.incidents_documented} type="boolean" />

      <WhiteSpace v="1rem" />
      <KeyValueRow {...securityOne.has_certificate} type="boolean" />
      <KeyValueRow {...securityOne.certificates} type="attachments" NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_apply_security} type="boolean" />
      <KeyValueRow {...securityOne.apply_security} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_special_requirements} type="boolean" />
      <WhiteSpace v="1rem" />

      <KeyValueRow {...securityOne.has_security_service} type="boolean" />
      <KeyValueRow {...securityOne.security_service} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_assessment_service} type="boolean" />
      <KeyValueRow {...securityOne.risks_identified} NA />
      <WhiteSpace v="2rem" />

      <KeyValueRow {...securityOne.has_client_requirements} type="boolean" />
      <KeyValueRow {...securityOne.client_requirements} NA />
      <WhiteSpace v="2rem" />

      <AttachmentsList />
    </>
  );
};
